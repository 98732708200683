import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
  Alert,
  Badge,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import NotificationAlert from 'react-notification-alert';
import AddEquipeModal from './components/AddEquipeModal';
import EmpresaContext from '../../../../../../contexts/Empresa';
import WhatsAppLoginButton from './components/WhatsAppLoginButton';
import { TutorialContainer } from './components/styles';
import api from '../../../../../../services/api';
import { IAccount } from '../../../../../marketing/Chat/types';
import WhatsAppVerifiedIcon from '../../../../../marketing/Chat/components/Icons/whatsapp-verified';
import WhatsAppUserIcon from '../../../../../marketing/Chat/components/Icons/whatsapp-user';
import WhatsAppIcon from '../../../../../marketing/Chat/components/Icons/whatsapp';
import AuthContext from '../../../../../../contexts/Auth';

interface AccountsList {
  hideTab: () => void;
  accounts: IAccount[];
  onSuccess: () => Promise<void>;
}

interface ClientInfoResponse {
  success: boolean;
  sessionInfo: {
    pushname: string;
    wid: {
      server: string;
      user: string;
      _serialized: string;
    };
    me: {
      server: string;
      user: string;
      _serialized: string;
    };
    platform: string;
  };
}

function formatPhoneNumber(phoneNumber: string) {
  // Adiciona o "+" no começo, os dois primeiros dígitos são o código do país
  const countryCode = `+${phoneNumber.slice(0, 2)}`;

  // Os próximos dois dígitos são o código de área
  const areaCode = phoneNumber.slice(2, 4);

  // Verifica se o número tem o '9' após o DDD
  const hasNine = phoneNumber[4] === '9';

  // Se tiver o '9', considera essa parte ao dividir o número
  const firstPart = hasNine ? phoneNumber.slice(5, 9) : phoneNumber.slice(4, 8);
  const secondPart = hasNine
    ? phoneNumber.slice(9, 13)
    : phoneNumber.slice(8, 12);

  // Formata o número final
  return `${countryCode} ${areaCode} ${
    hasNine ? '9 ' : ''
  }${firstPart}-${secondPart}`;
}

export default function AccountsListGroup({
  hideTab,
  accounts,
  onSuccess,
}: AccountsList) {
  const { empresaSelecionada } = useContext(EmpresaContext);
  const { user } = useContext(AuthContext);
  const notificationAlert = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accountSelecionada, setAccountSelecionada] = useState<string>('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [QRImage, setQRImage] = useState('');
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [isSessionConnected, setIsSessionConnected] = useState(false);
  const [modalWhatsApp, setModalWhatsApp] = useState(false);
  const [alert, setAlert] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  function openModal(accountId: string) {
    setIsModalOpen(true);
    setAccountSelecionada(accountId);
  }

  function closeModal() {
    setIsModalOpen(false);
    setAccountSelecionada('');
  }

  function toggleModal() {
    setIsModalOpen((prevState) => !prevState);
  }

  useEffect(() => {
    checkWhatsAppSessionStatus();
  }, []);

  function launchWhatsAppSignup() {
    //@ts-ignore
    FB.login(
      function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code;
          api
            .post('/meta/access-token', { code })
            .then((res) => onSuccess())
            .catch((err) => console.error(err));
        } else {
          console.log('User cancelled login or did not fully authorize.');
        }
      },
      {
        config_id: process.env.REACT_APP_FB_LOGIN_CONFIG_ID,
        response_type: 'code', // must be set to 'code' for System User access token
        override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
      }
    );
  }

  function notify(type: string, msg: string) {
    let options = {
      place: 'tc',
      message: (
        <div className="alert-text">
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: 'ni ni-bell-55',
      autoDismiss: 7,
    };
    if (notificationAlert?.current)
      //@ts-ignore
      notificationAlert.current.notificationAlert(options);
  }

  function toggleWhatsAppModal() {
    setModalWhatsApp((currentState) => !currentState);
  }

  function handleModalWhatsAppClose() {
    setModalWhatsApp(false);
    setQRImage('');
  }

  async function startSession() {
    const { data } = await api.post(`/meta/start`, {
      session: 'empresa',
      id: empresaSelecionada?.id,
    });
    if (data.success) {
      setIsSessionActive(true);
    }
  }

  async function fetchQRCode() {
    const { data } = await api.get(
      `/meta/qr/image?session=empresa&id=${empresaSelecionada?.id}`,
      { responseType: 'blob' }
    );

    const url = URL.createObjectURL(data);
    setQRImage(url);
  }

  async function checkWhatsAppSessionStatus() {
    const { data } = await api.get(
      `/meta/status?session=empresa&id=${empresaSelecionada?.id}`
    );

    if (data) {
      setIsSessionActive(data.state == 'CONNECTED' ? true : false);
    }
  }

  async function getClientInfo() {
    try {
      const { data } = await api.get<ClientInfoResponse>(
        `/meta/class-info?session=empresa&id=${empresaSelecionada?.id}`
      );

      if (data) {
        const {
          sessionInfo: { pushname, wid },
        } = data;

        // Se os dados da sessão existirem, registra a conta no sistema
        await api.post(`/meta/accounts/web`, {
          account: {
            verified_name: pushname,
            code_verification_status: 'VERIFIED',
            display_phone_number: wid.user,
            quality_rating: 'GREEN',
            id: wid.user,
          },
        });

        // Exibe o alerta de sucesso e fecha o modal
        window.alert('Conta sincronizada com sucesso!');
        setModalWhatsApp(false); // Fechar o modal
        checkWhatsAppSessionStatus();
        setQRImage('');
        // Retorna verdadeiro para parar o polling
        return true;
      }
    } catch (error) {
      console.error('Erro ao obter informações do cliente:', error);
    }

    // Retorna falso para continuar o polling
    return false;
  }

  async function unsubscribeAccount(account: IAccount) {
    if (account.api_meta) {
      // Cancelar a assinatura da WABA
    } else if (account.user_id) {
      setAlert(
        <ReactBSAlert
          danger
          title="Desativar número"
          onConfirm={() =>
            terminateWhatsAppSession({
              session: 'user',
              sessionId: user?.id,
              account_id: account.id,
            })
          }
          onCancel={() => setAlert(null)}
          showCancel
          disabled={isLoading}
          confirmBtnBsStyle="primary"
          confirmBtnText="Sim"
          cancelBtnBsStyle="link"
          cancelBtnText="Não"
          btnSize=""
        >
          Tem certeza que deseja desativar esse número de WhatsApp?
        </ReactBSAlert>
      );
    } else {
      setAlert(
        <ReactBSAlert
          danger
          title="Desativar número"
          onConfirm={() =>
            terminateWhatsAppSession({
              session: 'empresa',
              sessionId: empresaSelecionada?.id,
              account_id: account.id,
            })
          }
          onCancel={() => setAlert(null)}
          showCancel
          disabled={isLoading}
          confirmBtnBsStyle="primary"
          confirmBtnText="Sim"
          cancelBtnBsStyle="link"
          cancelBtnText="Não"
          btnSize=""
        >
          Tem certeza que deseja desativar esse número de WhatsApp?
        </ReactBSAlert>
      );
    }
  }

  async function terminateWhatsAppSession({ session, sessionId, account_id }) {
    // Possivelmente usar sessões separadas por accounts
    await api.post(`/meta/terminate`, {
      session: session,
      id: sessionId,
      account_id: account_id,
    });
    // checkWhatsAppSessionStatus();
    onSuccess();
    setAlert(null);
  }

  useEffect(() => {
    let pollingInterval: NodeJS.Timeout | undefined;

    if (QRImage) {
      // Quando a imagem do QR Code for exibida, inicia o polling
      pollingInterval = setInterval(async () => {
        const isClientSynced = await getClientInfo();

        if (isClientSynced) {
          // Se a conta for sincronizada, para o polling
          clearInterval(pollingInterval);
        }
      }, 3000);
    }

    // Limpa o polling se o componente for desmontado
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [QRImage]);

  if (accounts.length === 0) {
    return (
      <TutorialContainer>
        <legend>Atenção aos passos necessários</legend>
        <p>1. Integre usa conta do Facebook ao Playnee clicando no botão:</p>
        <div className="btn-container">
          <WhatsAppLoginButton onSuccess={onSuccess} />
        </div>
        <small className="d-inline-block mb-4">
          Siga as instruções do Facebook para integração.
        </small>
        <p>
          2. Uma vez realizada a integração, o Playnee irá listar o(s) número(s)
          de Whatsapp integrado(s) ao Playnee. Observe que ao lado de cada
          número listado você encontrará o botão para adicionar as equipes
          responsáveis pelo atendimento ao número.
        </p>
        <div className="mb-2">
          <span>Botão para adicionar as equipes:</span>
          <Button size="sm" className="ml-1" color="primary">
            Adicionar Equipes
          </Button>
        </div>
        <p>
          Essa informação é importante pois é a partir dela que saberemos para
          quem entregar os clientes que entram em contado com sua empresa por
          meio do número WhatsApp.
        </p>
        <Alert color="primary text-center">
          Observação: é necessário criar equipes de captação/atendimento antes
          de executar esta ação.
        </Alert>
      </TutorialContainer>
    );
  }

  return (
    <>
      {alert}

      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>

      <Modal isOpen={modalWhatsApp} toggle={handleModalWhatsAppClose}>
        <ModalHeader>WhatsApp</ModalHeader>
        <ModalBody>{QRImage && <img src={QRImage} />}</ModalBody>
        {isSessionActive && <Badge color="success">Conectado</Badge>}
        {!isSessionActive && <Badge color="danger">Desconectado</Badge>}
        <ModalFooter>
          {!isSessionActive && (
            <Button color="primary" onClick={fetchQRCode}>
              Iniciar Sessão
            </Button>
          )}
          <Button color="secondary" onClick={handleModalWhatsAppClose}>
            Voltar
          </Button>
        </ModalFooter>
      </Modal>

      <AddEquipeModal
        close={closeModal}
        show={isModalOpen}
        empresaId={empresaSelecionada?.id}
        accountId={accountSelecionada}
      />

      <div
        style={{
          flex: 1,
        }}
        className="mb-4 d-flex align-items-center justify-content-between"
      >
        <Button color="secondary" outline onClick={hideTab}>
          Voltar
        </Button>

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle size="sm" color="primary">
            Integrar com o WhatsApp
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={launchWhatsAppSignup}>
              Via API Meta
            </DropdownItem>
            <DropdownItem onClick={toggleWhatsAppModal}>
              Via WhatsApp Web
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      {accounts.length > 0 && (
        <ListGroup className="list mt-4" flush>
          <h3>Números Conectados</h3>
          {accounts.map((account, key) => (
            <ListGroupItem
              className="list-group-item-action px-0"
              style={{
                flexDirection: 'row',
                display: 'flex',
                flex: 1,
                alignItems: 'center',
              }}
              key={key}
            >
              <div className="col-auto">
                <Button
                  className="btn-sm"
                  color="danger"
                  onClick={() => {
                    unsubscribeAccount(account);
                  }}
                >
                  <i className="fas fa-trash"></i>
                </Button>
              </div>
              <Row style={{ flex: 1 }} className="align-items-center">
                <div className="col">
                  <h4 className="mb-0">
                    <span>
                      {account.api_meta ? (
                        <WhatsAppVerifiedIcon />
                      ) : account.user_id ? (
                        <WhatsAppUserIcon />
                      ) : (
                        <WhatsAppIcon heigth={26} width={26} />
                      )}
                    </span>
                    <span className="ml-1">{account.phone_number}</span>
                    <span>
                      {' - '}
                      {account.verified_name}
                    </span>
                    {account.situacao == 'I' && (
                      <Badge pill color="warning" className="ml-2">
                        Desconectado
                      </Badge>
                    )}
                  </h4>
                </div>
              </Row>
              <Button
                className="btn-sm"
                color="primary"
                onClick={() => openModal(account.id)}
              >
                Adicionar Equipes
              </Button>
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
    </>
  );
}
